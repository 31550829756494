.evenement{
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.event {
    width: 80%;
    height: 400px;
    display: flex;
    background-color: #EDEDED;
    border-radius: 50px;
    align-items: center;
    padding: 50px;
}

.event img {
    height: 300px;
    width: 40%;
    border-radius: 25px;
}

.annonce {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15%;
    font-size: 20px;
    
}

#descrip {
    margin-bottom: 20px;
}

#titre {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 30px;
}

.match {
    display: flex;
    position: absolute;
    right : 15%;
    margin-top: 220px;
    align-items: center;
}

#nb {
    margin: 5px;
}

.event:hover {
    background-color: #ED6247 ;
    color: white;
}

.i-size {
    width: 30px;
    height: 30px;
}
