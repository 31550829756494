.Nav {
    display: flex;
    background-color: #EDEDED;

}

.search {
    width: 110%;
    background-color: #ededed;
    color: #A5A5A5;
    border-radius: 10px;
}

.Sign {
    background-color: #ededed;
    color: black;
    text-transform: uppercase;
    padding: 1rem;
    border-radius: 10px;

}

.Log {
    
    background-color: black;
    color: white;
    text-transform: uppercase;
    padding: 1rem;
    border-radius: 10px;


}


.btn {
    display: flex;
    justify-content: center;
}

.size {
    height: 150px;
    width: 150px;
}

input{
    width: 400px;
}